/*
 * Use enum, which is compiled into an object at runtime, so we can iterate
 * over its keys.
 */
export enum EFlags {
  'bypassMX',
  'creditCardConvenienceFeeAccepted',
  'csmManager',
  'hasBlackbaudRaisersEdge',
  'hasGoogleAds',
  'hasImis',
  'hasMultiConversions',
  'locales',
  'minBudget',
  'monetization',
  'noMaxBudget',
  'pinpoint',
  'pinpointSendAllEmails',
  'showConversionsSettingsV2',
  'showDripCampaigns',
  'showDripBulkEnroll',
  'showForms',
  'showUsageMetrics',
  'showUsersAndRolesV2',
  'showCampaignRecommendations',
  'zapier',
}

export type TFlags = keyof typeof EFlags;

export type TFlagsRecord = Partial<Record<TFlags, boolean>>;
