import type { DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import classNames from 'classnames';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Form, IListRowItem, IRowItem } from '@feathr/blackbox';
import { defaultPersonAttributes, FieldCollection, FieldDataType } from '@feathr/blackbox';
import { Button, FullScreenModal, Modal, toast } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import Builder from './Builder';
import ConfigurationPanel from './ConfigurationPanel';
import type { IDragOverEvent } from './FormEditor.utils';
import { getListFieldOptions, onDragOver } from './FormEditor.utils';
import Field from './FormFields/Field';

import * as styles from './FormEditor.css';

interface IProps {
  form: Form;
  onValidationChange: (hasErrors: boolean) => void;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
}

export interface IFieldError {
  fieldId: string;
  message: string;
  type: 'label' | 'options';
}

function getFieldOptions(
  dataType: string,
  formConfig: Form['formConfig'],
  fieldId: string,
): string[] | undefined {
  return dataType === 'list' ? getListFieldOptions({ config: formConfig, fieldId }) : undefined;
}

function FormEditor({
  form,
  onValidationChange,
  isFullScreen,
  toggleFullScreen,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { CustomFields } = useStore();
  const [allFields, setAllFields] = useState<IRowItem[]>([]);
  const [availableFields, setAvailableFields] = useState<IRowItem[]>([]);
  const [usedFields, setUsedFields] = useState<IRowItem[]>([]);
  const [activeField, setActiveField] = useState<UniqueIdentifier | undefined>(undefined);
  const [focusedField, setFocusedField] = useState<IRowItem | undefined>(undefined);
  const [validationErrors, setValidationErrors] = useState<IFieldError[]>([]);
  const [touchedFields, setTouchedFields] = useState<Set<string>>(new Set());
  const [isSubmitButtonFocused, setIsSubmitButtonFocused] = useState(false);
  const mouseSensor = useSensor(MouseSensor);
  const sensors = useSensors(mouseSensor);
  const [isDeleteModalOpen, toggleDeleteModalOpen] = useToggle(false);

  useEffect(() => {
    async function init(): Promise<void> {
      // List of available field ids for MVP
      const allowedFormFieldIds = [
        'first_name',
        'last_name',
        'name',
        'email',
        'phone',
        'occupation',
      ];

      const defaultFieldOptions = defaultPersonAttributes
        .filter((field) => allowedFormFieldIds.includes(field.id))
        .map((field) => ({
          id: field.id,
          label: field.u_key,
          name: field.u_key,
          required: field.id === 'email',
          type: field.data_type as FieldDataType,
          options: getFieldOptions(field.data_type, form.formConfig, field.id),
        }));

      try {
        const customFields = CustomFields.list({ filters: { collection: FieldCollection.Person } });
        await when(() => !customFields.isPending);
        if (customFields.isErrored) {
          toast(t('Failed to load custom fields: {{- error}}', { error: customFields.error }), {
            type: ToastType.ERROR,
          });
          setAllFields(defaultFieldOptions);
          return;
        }
        const customFieldOptions = customFields.models.map((field) => ({
          id: field.get('u_key'),
          label: field.get('u_key'),
          name: field.get('u_key'),
          type: field.get('data_type'),
          options: getFieldOptions(field.get('data_type'), form.formConfig, field.get('u_key')),
        }));

        const allOptions = [...defaultFieldOptions, ...customFieldOptions];

        const usedOptions = form.usedFields;
        const usedIds = usedOptions.map((field) => field.id);
        const availableOptions = allOptions.filter((field) => !usedIds.includes(field.id));
        setAllFields(allOptions);
        setUsedFields(usedOptions);
        setAvailableFields(availableOptions);
      } catch (error) {
        toast(t('Failed to load custom fields: {{- error}}', { error }), {
          type: ToastType.ERROR,
        });
        setAllFields(defaultFieldOptions);
      }
    }

    init();
  }, []);

  function handleDragStart(event: DragStartEvent): void {
    setActiveField(event.active.id);
  }

  function handleDragOver(event: IDragOverEvent): void {
    onDragOver({
      availableFields,
      setAvailableFields,
      usedFields,
      setUsedFields,
      ...event,
    });
  }

  function handleFocusField(fieldOrUpdater: SetStateAction<IRowItem | undefined>): void {
    setIsSubmitButtonFocused(false);
    const field =
      typeof fieldOrUpdater === 'function' ? fieldOrUpdater(focusedField) : fieldOrUpdater;
    setFocusedField(field);
  }

  function handleFieldBlur(fieldId: string): void {
    setTouchedFields((prev) => new Set(prev).add(fieldId));

    // Trim whitespace from the focused field's values when it loses focus
    if (focusedField && focusedField.id === fieldId) {
      const trimmedField = { ...focusedField };
      // Trim any string values in the field
      Object.keys(trimmedField).forEach((key) => {
        if (typeof trimmedField[key] === 'string') {
          trimmedField[key] = trimmedField[key].trim();
        }
      });

      setFocusedField(trimmedField);
      setUsedFields((prevFields) =>
        prevFields.map((field) => (field.id === fieldId ? trimmedField : field)),
      );
    }
  }

  function validateAllFields(fields: IRowItem[]): IFieldError[] {
    const errors: IFieldError[] = [];

    // Check if there are no fields added
    if (fields.length === 0) {
      errors.push({
        fieldId: 'form',
        message: t('At least one field must be added to the form'),
        type: 'label',
      });
      return errors;
    }

    if (form.formConfig.settings.submitLabel.trim() === '') {
      errors.push({
        fieldId: 'submitLabel',
        message: t('Submit button label cannot be empty'),
        type: 'label',
      });
    }

    fields.forEach((field) => {
      if (!field.label?.trim()) {
        errors.push({
          fieldId: field.id,
          message: t('Field labels cannot be empty'),
          type: 'label',
        });
      }
      if (field.type === FieldDataType.list) {
        const listField = field as IListRowItem;
        if (!listField.options || listField.options.length === 0) {
          errors.push({
            fieldId: field.id,
            message: t('List fields must have at least one option'),
            type: 'options',
          });
        }
      }
    });
    return errors;
  }

  useEffect(() => {
    const allErrors = validateAllFields(usedFields);
    setValidationErrors(allErrors);

    onValidationChange(!!allErrors.length);
  }, [usedFields, touchedFields, form.formConfig.settings?.submitLabel]);

  function updateFieldProperties(key: UniqueIdentifier, value: unknown): void {
    if (focusedField !== undefined) {
      const updatedField = { ...focusedField, [key]: value };
      setFocusedField(updatedField);

      function updateField(prevField: IRowItem): IRowItem {
        if (prevField.id === focusedField!.id) {
          return updatedField;
        }
        return prevField;
      }

      setUsedFields((prevFields) => prevFields.map(updateField));
    }
  }

  function handleDeleteField(): void {
    if (focusedField && focusedField.id !== 'email') {
      setUsedFields((prevFields) => prevFields.filter((field) => field.id !== focusedField.id));
      setAvailableFields((prevFields) => [...prevFields, focusedField]);
      setFocusedField(undefined);
      toggleDeleteModalOpen();
    }
  }

  const props = {
    form,
    focusedField,
    onFocusField: handleFocusField,
    onFieldBlur: handleFieldBlur,
    isSubmitButtonFocused,
    onFocusSubmitButton: setIsSubmitButtonFocused,
    updateFieldProperties,
    onDeleteField: toggleDeleteModalOpen,
    validationErrors,
  };
  const base = (
    <div className={classNames(styles.root, { [styles.fullscreen]: isFullScreen })}>
      <DndContext onDragOver={handleDragOver} onDragStart={handleDragStart} sensors={sensors}>
        <SortableContext
          id={'configuration-panel'}
          items={availableFields}
          strategy={rectSortingStrategy}
        >
          <ConfigurationPanel {...props} fields={availableFields} />
        </SortableContext>
        <SortableContext id={'form-builder'} items={usedFields} strategy={rectSortingStrategy}>
          <Builder {...props} fields={usedFields} />
        </SortableContext>
        <DragOverlay className={styles.dragOverlay}>
          {activeField && allFields.find((field) => field.id === activeField) ? (
            <Field
              field={allFields.find((field) => field.id === activeField)!}
              isDragOverlay={true}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
      <Modal
        description={t('Are you sure you want to delete this field?')}
        onClose={toggleDeleteModalOpen}
        opened={isDeleteModalOpen}
        rightActions={
          <>
            <Button onClick={toggleDeleteModalOpen}>{t('Cancel')}</Button>
            <Button onClick={handleDeleteField} type={'danger'}>
              {t('Delete')}
            </Button>
          </>
        }
        title={t('Delete field')}
      />
    </div>
  );

  return (
    <>
      {isFullScreen ? (
        <FullScreenModal onClose={toggleFullScreen} opened={isFullScreen} title={t('Form Editor')}>
          {base}
        </FullScreenModal>
      ) : (
        base
      )}
    </>
  );
}

export default observer(FormEditor);
