import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import CampaignPage from '@feathr/extender/App/EventsPage/CampaignPage';
import ReportActions from '@feathr/extender/components/PageHeaderActions';
import { useReportExport } from '@feathr/extender/hooks';
import { useFlags } from '@feathr/extender/state';

import CampaignReportSection, { getCampaignReportConfig } from '../CampaignReportSection';
import CampaignRecommendations, { CAMPAIGN_RECOMMENDATIONS } from './CampaignRecommendations';

interface IProps {
  campaign: Campaign;
  eventId: string;
}

function DefaultCampaignPage({ campaign }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const config = getCampaignReportConfig(campaign);
  const reportExportProps = useReportExport({ model: campaign, config });
  const { showCampaignRecommendations } = useFlags();

  const actions = <ReportActions campaign={campaign} {...reportExportProps} />;

  return (
    <CampaignPage actions={actions} campaign={campaign} title={campaign.name}>
      {/* TODO: pull these recommendations from the backend  */}
      {campaign.isTTDCampaign && showCampaignRecommendations && (
        <CampaignRecommendations recommendations={CAMPAIGN_RECOMMENDATIONS} />
      )}
      <h3>{t('Report')}</h3>
      <CampaignReportSection campaign={campaign} {...reportExportProps} />
    </CampaignPage>
  );
}

export default DefaultCampaignPage;
