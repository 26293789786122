import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { FocusEvent, JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ValueType } from 'react-select';

import type { TTDCampaign } from '@feathr/blackbox';
import { AlertV2, CreatableSelect, EAlertV2Type, Tooltip } from '@feathr/components';
import { useRole } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import * as styles from './BaseBidSelect.css';

export interface IBaseBidSelectProps {
  campaign: TTDCampaign;
}

interface IOption {
  value: string;
  label: string;
}

function compareOption(inputValue: string, option: IOption): boolean {
  return inputValue === option.value;
}

function createOption(inputValue: string): IOption {
  const asNumeral = numeral(Number(inputValue));
  return { value: asNumeral.format('0.00'), label: asNumeral.format('$0,0.00') };
}

const MIN_BID = 5;
const MIN_BID_SUDOER_MONETIZATION = 3;
const MAX_SUGGESTED_BID = 10;
const MAX_BID = 99;

function BaseBidSelect({ campaign }: Readonly<IBaseBidSelectProps>): JSX.Element {
  const { t } = useTranslation();
  const { isSudoer } = useRole();
  const isSudoerAndMonetization = isSudoer && campaign.isMonetization;

  const bid = campaign.get('bidding_strategy').base_bid;

  const onChangeBaseBid = (value: string | number): void => {
    const newBid = Number(value);
    const biddingStrategy = {
      ...campaign.get('bidding_strategy'),
      base_bid: newBid,
      max_bid: newBid * 3,
      custom_bid: true,
    };
    campaign.set({
      bidding_strategy: biddingStrategy,
    });
  };

  const defaultOptions: IOption[] = [
    { value: '5', label: '$5.00' },
    { value: '7', label: '$7.00' },
    { value: '10', label: '$10.00' },
  ];

  if (isSudoerAndMonetization) {
    defaultOptions.unshift({ value: '4', label: '$4.00' });
    defaultOptions.unshift({ value: '3', label: '$3.00' });
  }

  function isValidValue(inputValue: string): boolean {
    const asNumber = Number(inputValue);
    const min = isSudoerAndMonetization ? MIN_BID_SUDOER_MONETIZATION : MIN_BID;
    const max = MAX_BID;
    return (
      // No empty strings
      !!inputValue &&
      // No non-numbers
      Number.isFinite(asNumber) &&
      // Between min and max
      asNumber >= min &&
      asNumber <= max
    );
  }

  function isValidNewOption(
    inputValue: string,
    _: ValueType<IOption>,
    options: readonly IOption[],
  ): boolean {
    return !(
      !isValidValue(inputValue) ||
      // No duplicates
      options.some((option) => compareOption(inputValue, option))
    );
  }

  function formatCreateLabel(inputValue: string): string {
    return t('Use custom value: {{value}}', {
      value: numeral(Number(inputValue)).format('$0,0.00'),
    });
  }

  function handleSelect(option: IOption): void {
    if (!isValidValue(option.value)) {
      return;
    }
    onChangeBaseBid(option.value);
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>): void {
    const value = e.currentTarget.value;
    if (!isValidValue(value)) {
      return;
    }
    onChangeBaseBid(value);
  }

  const label = (
    <>
      {t('Base bid')}{' '}
      <Tooltip
        title={t(
          'The starting bid Feathr will use before adjustments are applied. The number is expressed as a cost for a thousand impressions at the base bid.',
        )}
      >
        {getIconForAction('info')}
      </Tooltip>
    </>
  );

  return (
    <>
      <CreatableSelect<IOption>
        createOption={createOption}
        data-name={'base-bid'}
        defaultOptions={defaultOptions}
        formatCreateLabel={formatCreateLabel}
        isFullWidth={true}
        isValidNewOption={isValidNewOption}
        label={label}
        name={'base-bid'}
        onBlur={handleBlur}
        onSelectSingle={handleSelect}
        value={{
          value: String(bid),
          label: numeral(bid).format('$0,0.00'),
        }}
      />
      {bid && bid > MAX_SUGGESTED_BID && (
        <AlertV2
          className={styles.alert}
          description={t(
            'Your campaign is at risk of depleting budget too quickly. We recommend a base bid between $5 and $10.',
          )}
          title={t('Your base bid is very high')}
          type={EAlertV2Type.warning}
        />
      )}
      {bid && bid < MIN_BID && (
        <AlertV2
          className={styles.alert}
          description={t(
            'With this base bid, your campaign is at risk of not winning sufficient impressions. We recommend a base bid between $5 and $10.',
          )}
          title={t('Your base bid is very low')}
          type={EAlertV2Type.warning}
        />
      )}
    </>
  );
}

export default observer(BaseBidSelect);
