import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faChartMixedUpCircleCurrency, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardV2 as Card, CollapseButton, Recommendation } from '@feathr/components';

import * as styles from './CampaignRecommendations.css';

interface ICampaignRecommendation {
  title: string;
  description: string;
  action: string;
  icon: IconDefinition;
}

interface ICampaignRecommendationsProps {
  recommendations: ICampaignRecommendation[];
}

function CampaignRecommendations({ recommendations }: ICampaignRecommendationsProps): JSX.Element {
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();
  const [currentRecommendations, setCurrentRecommendations] = useState(recommendations);

  function handleCollapse(): void {
    setCollapsed(!collapsed);
  }

  function handleApplyRecommendation(title: string): () => void {
    return function (): void {
      setCurrentRecommendations((prevRecommendations) =>
        prevRecommendations.filter((rec) => rec.title !== title),
      );
    };
  }

  const collapseText = collapsed ? t('View more') : t('View less');

  // Determine the recommendations to display based on the collapsed state
  const displayedRecommendations = collapsed
    ? currentRecommendations.slice(0, 1)
    : currentRecommendations;

  return (
    <Card width={'full'}>
      <Card.Header title={'Recommended for you'}></Card.Header>
      <Card.Content addVerticalGap={true}>
        {displayedRecommendations.length > 0 ? (
          <>
            {displayedRecommendations.map((recommendation) => (
              <Recommendation
                description={recommendation.description}
                key={recommendation.title}
                suffix={<Recommendation.Icon icon={recommendation.icon} />}
                title={recommendation.title}
              >
                <Recommendation.Button onClick={handleApplyRecommendation(recommendation.title)}>
                  {recommendation.action}
                </Recommendation.Button>
              </Recommendation>
            ))}
            <CollapseButton
              className={styles.collapseButton}
              collapsed={collapsed}
              icons={{
                collapsedLeft: faChevronDown,
                collapsedRight: faChevronDown,
                expanded: faChevronUp,
              }}
              onClick={handleCollapse}
            >
              {collapseText}
            </CollapseButton>
          </>
        ) : (
          <Recommendation
            description={t('You completed all recommendations.')}
            layout={'vertical'}
            suffix={<Recommendation.Icon bordered={true} icon={faChartMixedUpCircleCurrency} />}
            title={t('You did it!')}
          />
        )}
      </Card.Content>
    </Card>
  );
}

export default CampaignRecommendations;
