import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Form } from '@feathr/blackbox';
import { ColorInput, NumberInput, SelectionMatrix } from '@feathr/components';
import { cssVar } from '@feathr/hooks';

import TypefaceSelect from './TypefaceSelect';

import * as styles from './Design.css';

interface IProps {
  form: Form;
}

function Design({ form }: IProps): JSX.Element {
  const { t } = useTranslation();

  const {
    typeface = 'Inter',
    fieldLabelColor = cssVar('--color-text-heading'),
    fieldLabelSize = '14',
    helpTextColor = cssVar('--color-text-body'),
    helpTextSize = '10',
    submitButtonColor = cssVar('--color-action'),
    submitButtonTextColor = cssVar('--color-white'),
    buttonShape = 'rounded',
  } = form.formConfig.settings;

  function onChangeTypeface(typeface: string): void {
    form.updateDesign('typeface', typeface);
  }

  function onChangeFieldLabelColor(color: string): void {
    form.updateDesign('fieldLabelColor', color);
  }

  function onChangeFieldLabelSize(size?: number): void {
    form.updateDesign('fieldLabelSize', size?.toString() ?? '10');
  }

  function onChangeHelpTextSize(size?: number): void {
    form.updateDesign('helpTextSize', size?.toString() ?? '8');
  }

  function onChangeHelpTextColor(color: string): void {
    form.updateDesign('helpTextColor', color);
  }

  function onChangeSubmitButtonColor(color: string): void {
    form.updateDesign('submitButtonColor', color);
  }

  function onChangeSubmitButtonTextColor(color: string): void {
    form.updateDesign('submitButtonTextColor', color);
  }

  function onChangeButtonShape(shape: string): void {
    form.updateDesign('buttonShape', shape);
  }

  return (
    <div className={styles.root}>
      <TypefaceSelect onChange={onChangeTypeface} value={typeface} />
      <div className={styles.section}>
        <ColorInput
          label={t('Field label color')}
          onChange={onChangeFieldLabelColor}
          value={fieldLabelColor}
        />
        <NumberInput.Increment
          label={t('Field label size')}
          max={42}
          min={10}
          onChange={onChangeFieldLabelSize}
          value={Number(fieldLabelSize)}
        />
      </div>
      <div className={styles.section}>
        <ColorInput
          label={t('Help text color')}
          onChange={onChangeHelpTextColor}
          value={helpTextColor}
        />
        <NumberInput.Increment
          label={t('Help text size')}
          max={24}
          min={8}
          onChange={onChangeHelpTextSize}
          value={Number(helpTextSize)}
        />
      </div>
      <div className={styles.section}>
        <ColorInput
          label={t('Button color')}
          onChange={onChangeSubmitButtonColor}
          value={submitButtonColor}
        />
        <ColorInput
          label={t('Button text color')}
          onChange={onChangeSubmitButtonTextColor}
          value={submitButtonTextColor}
        />
      </div>
      <SelectionMatrix.ButtonShape
        buttonColor={submitButtonColor}
        buttonTextColor={submitButtonTextColor}
        onChange={onChangeButtonShape}
        t={t}
        value={buttonShape}
      />
    </div>
  );
}

export default observer(Design);
