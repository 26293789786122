import { faGlobe, faHistory, faStar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';

import type { Account } from '@feathr/blackbox';
import { Button, Tooltip } from '@feathr/components';
import { useAccount, useLocalUrl, useStore, useUser } from '@feathr/extender/state';
import { useDebounce } from '@feathr/hooks';
import type { Attributes, IListParams, ListResponse } from '@feathr/rachis';

import Switcher from '../Switcher';

import * as styles from './AccountSwitcher.css';

interface IAccountSwitcherProps {
  className?: string;
}

type TToggle = 'favorites' | 'recent' | undefined;

function AccountSwitcher({ className }: IAccountSwitcherProps): JSX.Element {
  const { Accounts } = useStore();
  const account = useAccount();
  const localUrl = useLocalUrl();
  const user = useUser();

  const favorites = user.getSetting('favorite_account_ids') || observable([] as string[]);
  const filteredFavorites = favorites.filter((value) => value !== account.id);

  const recents = user.get('recent_account_ids');
  const filteredRecents = recents.filter((value) => value !== account.id);

  const hasFavorites = user.isAdmin || user.isSudoer;

  const [toggle, setToggle] = useState<TToggle>(
    hasFavorites && favorites.length ? 'favorites' : undefined,
  );
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [search, setSearch, setSearchInstantly] = useDebounce<string | undefined>();

  useEffect(() => {
    // Can't see favorites if there are no favorites.
    if (toggle === 'favorites' && !filteredFavorites.length) {
      setToggle(undefined);
    } else if (toggle === undefined && hasFavorites && filteredFavorites.length) {
      setToggle('favorites');
    }
  }, [favorites]);

  const listParams: IListParams<Attributes<Account>> = {
    filters: {
      id__ne: account.id,
      is_archived__ne: true,
    },
    only: ['id', 'name', 'logo'],
    pagination: {
      page_size: 10,
    },
    ordering: ['name'],
  };
  const allAccounts = Accounts.list({ ...listParams });

  const hasRecent = !allAccounts.isPending && allAccounts.models.length > 0;

  // Only show favorites or history when not searching.
  if (search) {
    listParams.filters!.name__icontains = search;
  } else if (toggle === 'favorites' && filteredFavorites.length) {
    listParams.filters!.id__in = filteredFavorites;
  } else if (toggle === 'recent' && filteredRecents.length) {
    listParams.filters!.id__in = filteredRecents;
    delete listParams.ordering;
  }

  // Only do an additional fetch if we are filtering results.
  let accounts: ListResponse<Account>;
  if (toggle === 'recent' && !filteredRecents.length) {
    // Displaying recent, but there are none.
    accounts = Accounts.newListResponse();
  } else if (
    search ||
    (toggle === 'favorites' && filteredFavorites.length) ||
    toggle === 'recent'
  ) {
    // Displaying filtered results.
    accounts = Accounts.list(listParams);
  } else {
    // Displaying all results.
    accounts = allAccounts;
  }

  const url = new URL(window.location.href);

  const buttonFavorites = (
    <Tooltip
      key={'favorites'}
      title={filteredFavorites.length ? 'Favorites' : 'Add some favorite accounts'}
    >
      <Button
        className={styles.iconButton}
        disabled={!filteredFavorites.length}
        onClick={() => {
          setToggle('favorites');
          setInputValue(undefined);
          setSearchInstantly(undefined);
        }}
        type={toggle === 'favorites' && !search ? 'clicked' : 'icon-outlined'}
      >
        <FontAwesomeIcon icon={faStar} />
      </Button>
    </Tooltip>
  );
  const buttonRecent = (
    <Tooltip key={'recent'} title={'Recent'}>
      <Button
        className={styles.iconButton}
        onClick={() => {
          setToggle('recent');
          setInputValue(undefined);
          setSearchInstantly(undefined);
        }}
        type={toggle === 'recent' && !search ? 'clicked' : 'icon-outlined'}
      >
        <FontAwesomeIcon icon={faHistory} />
      </Button>
    </Tooltip>
  );
  const buttonAll = (
    <Tooltip key={'all'} title={'All'}>
      <Button
        className={styles.iconButton}
        onClick={() => {
          setToggle(undefined);
          setInputValue(undefined);
          setSearchInstantly(undefined);
        }}
        type={toggle === undefined && !search ? 'clicked' : 'icon-outlined'}
      >
        <FontAwesomeIcon icon={faGlobe} />
      </Button>
    </Tooltip>
  );

  const buttons =
    hasFavorites && hasRecent
      ? [buttonFavorites, buttonRecent, buttonAll]
      : [buttonRecent, buttonAll];

  return (
    <Switcher
      buttons={buttons}
      className={className}
      count={allAccounts.isPending ? undefined : allAccounts.models.length}
      currentItem={{ id: account.id, logo: account.get('logo'), name: account.get('name') }}
      dropdownClassName={styles.root}
      hasMore={toggle === undefined && !accounts.isPending && accounts.models.length > 0}
      isLoading={allAccounts.isPending || accounts.isPending}
      items={accounts.models.map((a) => {
        const pathParts = url.pathname.split('/');
        pathParts.splice(1, 1, a.id);
        return {
          id: a.id,
          logo: a.get('logo'),
          name: a.get('name'),
          url: `${pathParts.slice(0, 3).join('/')}${
            pathParts.length <= 3 ? `${url.search}${url.hash}` : ''
          }`,
        };
      })}
      // Use href instead of link to bypass react router to enforce a complete refresh.
      linkType={'href'}
      placeholder={'Find an account...'}
      search={inputValue}
      setSearch={(newValue) => {
        setInputValue(newValue);
        setSearch(newValue);
      }}
      suffix={
        hasFavorites ? (
          <Button link={localUrl('/accounts')} type={'link'}>
            View all accounts
          </Button>
        ) : undefined
      }
    />
  );
}

export default observer(AccountSwitcher);
