import { useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Form, IRowItem } from '@feathr/blackbox';
import { List, ListItem } from '@feathr/components';

import FormFields from '../../FormFields';
import Design from './Design';

import * as styles from './FieldConfiguration.css';

interface IProps {
  fields: IRowItem[];
  form: Form;
}

function FieldConfiguration({ fields, form }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<'form-fields' | 'design'>('form-fields');
  const { setNodeRef: setDroppableRef } = useDroppable({
    id: 'configuration-panel-context',
  });

  function handleClickFormFields(): void {
    setActiveTab('form-fields');
  }

  function handleClickDesign(): void {
    setActiveTab('design');
  }

  return (
    <>
      <List className={styles.tabs} role={'tablist'}>
        <ListItem
          ariaSelected={activeTab === 'form-fields'}
          className={classNames({ [styles.active]: activeTab === 'form-fields' })}
          onClick={handleClickFormFields}
          role={'tab'}
          tabIndex={activeTab === 'form-fields' ? 0 : -1}
        >
          {t('Form Fields')}
        </ListItem>
        <ListItem
          ariaSelected={activeTab === 'design'}
          className={classNames({ [styles.active]: activeTab === 'design' })}
          onClick={handleClickDesign}
          role={'tab'}
          tabIndex={activeTab === 'design' ? 0 : -1}
        >
          {t('Design')}
        </ListItem>
      </List>
      <div
        aria-label={t('Droppable form configuration area')}
        className={styles.content}
        ref={setDroppableRef}
        role={'region'}
      >
        {activeTab === 'form-fields' && <FormFields fields={fields} />}
        {activeTab === 'design' && <Design form={form} />}
      </div>
    </>
  );
}

export default observer(FieldConfiguration);
