import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { AggregatedStat, Campaign, IPersonsListParams, IPredicate } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Table, TableStatsCard, Value, VerifiedActivityToggle } from '@feathr/components';
import { EEmailStats, getEmailStats } from '@feathr/report_components/components/EmailStats';
import { EReportFlavors, useStore } from '@feathr/report_components/state';

import { ReportContext } from '../Report.context';
import campaignEngagementColumns from './campaignEngagementColumns';
import CampaignInteractionSelect from './CampaignInteractionSelect';
import CreateGroupButton from './CreateGroupButton';

import * as styles from './CampaignEngagementCard.css';

interface IProps {
  aggregatedStats: AggregatedStat;
  campaign: Campaign;
  localUrl: ((url: string) => string) | undefined;
  dateRange: {
    start: string;
    end: string;
  };
}

interface IEngagementPredicate {
  docvalue_fields: string[];
  name: string;
  size: number;
  sort: {
    'breadcrumbs.d_c': string;
  };
}

interface IEngagementPredicateGroup {
  group: IPredicate[];
  inner_hits: IEngagementPredicate;
}

// Override predicates field typing on IPersonsListParams to allow for custom query.
export interface IEngagementParams extends Omit<IPersonsListParams, 'predicates'> {
  /*
   * We can sort on specific fields on people, in this case we are sorting on
   * person.inner_hits to get the person's first crumb for a given flavor and campaign.
   */
  predicates: IPredicate[] | IEngagementPredicateGroup[];
}

export const flavorToInteractionMap = (t: TFunction): Record<string, string> => ({
  // Ad campaigns
  [EReportFlavors.ad_view]: t('Viewed ad'),
  [EReportFlavors.ad_click]: t('Clicked ad'),
  // Email campaigns
  [EReportFlavors.pinpoint_tracked_email_send]: t('Sent'),
  [EReportFlavors.pinpoint_tracked_email_hardbounce]: t('Hard bounce'),
  [EReportFlavors.pinpoint_tracked_email_softbounce]: t('Soft bounce'),
  [EReportFlavors.pinpoint_tracked_email_delivered]: t('Received'),
  [EReportFlavors.pinpoint_tracked_email_open]: t('Opened'),
  [EReportFlavors.pinpoint_tracked_email_click]: t('Clicked'),
  [EReportFlavors.pinpoint_tracked_email_complaint]: t('Reported as spam'),
  [EReportFlavors.email_preferences_update]: t('Unsubscribed from all'),
});

const { Complaints, Deliveries, HardBounces, Sends, SoftBounces, UniqueClicks, UniqueOpens } =
  EEmailStats;

const emailFlavorToStatMap: Record<string, string> = {
  [EReportFlavors.pinpoint_tracked_email_hardbounce]: HardBounces,
  [EReportFlavors.pinpoint_tracked_email_send]: Sends,
  [EReportFlavors.pinpoint_tracked_email_softbounce]: SoftBounces,
  [EReportFlavors.pinpoint_tracked_email_delivered]: Deliveries,
  [EReportFlavors.pinpoint_tracked_email_open]: UniqueOpens,
  [EReportFlavors.pinpoint_tracked_email_click]: UniqueClicks,
  [EReportFlavors.pinpoint_tracked_email_complaint]: Complaints,
};

const adFlavorToStatMap: Record<string, string> = {
  [EReportFlavors.ad_view]: 'views',
  [EReportFlavors.ad_click]: 'clicks',
};

function getAdStats(stats: AggregatedStat): Record<string, number> {
  return {
    // We want unique clicks, not total clicks
    clicks: stats.get('num_clicks_new', 0),
    views: stats.get('num_users_new', 0),
  };
}

export function getPredicates(
  campaignId: string,
  flavor: EReportFlavors,
  dateStart?: string,
  dateEnd?: string,
): IPredicate[] | IEngagementPredicateGroup[] {
  const flavorIsUnsubscribe = flavor === EReportFlavors.email_preferences_update;
  const group: IPredicate[] = [
    {
      kind: 'activity',
      attr_against: 'cpn_id',
      attr_type: 'string',
      comparison: 'eq',
      value: campaignId,
    },
    {
      kind: 'activity',
      attr_against: 'flvr',
      attr_type: 'string',
      comparison: 'eq',
      value: flavor,
    },
  ];
  if (dateStart) {
    group.push({
      kind: 'activity',
      attr_against: 'd_c',
      attr_type: 'date',
      comparison: 'gte_date',
      value: dateStart,
    });
  }
  if (dateEnd) {
    group.push({
      kind: 'activity',
      attr_against: 'd_c',
      attr_type: 'date',
      comparison: 'lte_date',
      value: dateEnd,
    });
  }
  if (flavorIsUnsubscribe) {
    group.push({
      kind: 'activity',
      attr_against: 'msg',
      attr_type: 'string',
      comparison: 'contains_substring',
      value: '"global": true',
    });
  }

  return [
    {
      group,
      inner_hits: {
        name: 'first_flavor',
        sort: {
          'breadcrumbs.d_c': 'asc',
        },
        size: 1,
        docvalue_fields: ['breadcrumbs.d_c'],
      },
    },
  ];
}

function CampaignEngagementCard({
  aggregatedStats,
  campaign,
  dateRange,
  localUrl,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Persons } = useStore();

  const { prioritizeVerifiedActivity, setPrioritizeVerifiedActivity } =
    useContext(ReportContext) ?? {};

  const {
    ad_view: adView,
    email_preferences_update: emailPreferencesUpdate,
    pinpoint_tracked_email_send: emailSend,
  } = EReportFlavors;
  const [flavor, setFlavor] = useState(campaign.isAdCampaign ? adView : emailSend);
  const flavorIsUnsubscribe = flavor === emailPreferencesUpdate;

  const params = (function (): IEngagementParams {
    const { start: dateStart, end: dateEnd } = dateRange;

    const predicates = getPredicates(campaign.id, flavor, dateStart, dateEnd);

    return {
      mode: 'match_all',
      lookback_mode: 'unbounded',
      exclude: ['breadcrumbs'],
      predicates,
    };
  })();

  function handleSelectFlavor({ id }: ISelectOption): void {
    setFlavor(id as EReportFlavors);
  }

  const [map, statFunc] = campaign.isAdCampaign
    ? [adFlavorToStatMap, getAdStats]
    : [emailFlavorToStatMap, getEmailStats];

  // Do not show the create group button on anonymous reports
  const isAnonymousReport = !localUrl;
  const actions = !isAnonymousReport
    ? [
        <CreateGroupButton
          campaign={campaign}
          dateEnd={dateRange.end}
          dateStart={dateRange.start}
          flavor={flavor}
          key={'create-group-button'}
          localUrl={localUrl}
        />,
      ]
    : undefined;

  // Only show the verified activity toggle for unique opens and clicks
  const selectedRelevantFlavor = map[flavor] === UniqueOpens || map[flavor] === UniqueClicks;
  if (!campaign.isAdCampaign && !isAnonymousReport && selectedRelevantFlavor) {
    actions?.unshift(
      <VerifiedActivityToggle
        key={'verified-activity-toggle'}
        onChange={setPrioritizeVerifiedActivity}
        value={prioritizeVerifiedActivity}
      />,
    );
  }

  /*
   * Use the Persons.count endpoint to get unique unsubscribes
   * because it's not on the AggregatedStats.
   */
  const uniquePersons = (function (): { number: string; count: number } {
    const total = flavorIsUnsubscribe
      ? Persons.list(params, { url: Persons.url('page') })
      : statFunc(aggregatedStats)[map[flavor]];

    return flavorIsUnsubscribe
      ? {
          number: numeral(total.isPending ? '-' : total.pagination.count).format('0,0'),
          count: total.isPending ? 0 : total.pagination.count,
        }
      : {
          number: numeral(total).format('0,0'),
          count: total,
        };
  })();

  return (
    <TableStatsCard actions={actions} title={reportModuleLabels.includeCampaignEngagement}>
      <div className={styles.header}>
        <div className={styles.selectWrapper}>
          <CampaignInteractionSelect
            campaign={campaign}
            flavor={flavor}
            onSelectSingle={handleSelectFlavor}
          />
          <Value
            className={styles.totalInteractions}
            label={
              [
                EReportFlavors.pinpoint_tracked_email_hardbounce,
                EReportFlavors.pinpoint_tracked_email_softbounce,
              ].includes(flavor)
                ? t('Unique {{flavor}}d:', { flavor: flavorToInteractionMap(t)[flavor] })
                : t('Unique {{flavor}}:', { flavor: flavorToInteractionMap(t)[flavor] })
            }
            value={t('{{count}} Person', {
              count: uniquePersons.count,
            })}
          />
        </div>
      </div>
      <Table
        collection={Persons}
        columns={campaignEngagementColumns(t, localUrl, flavor)}
        listOptions={{ url: Persons.url('page') }}
        params={params}
      />
    </TableStatsCard>
  );
}

export default observer(CampaignEngagementCard);
