import { action, computed, makeObservable, runInAction, when } from 'mobx';

import { concatPath, toQueryString } from '@feathr/hooks';
import type { Attributes, IBaseAttributes, IConstraint, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

import type { FieldDataType } from './custom_fields';
import type { IRedirectDomain } from './redirect_domains';

interface IFormStats {
  num_crumbs: number;
  num_views: number;
  num_submissions: number;
  submission_rate: number;
}

export enum EFormState {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export interface IRowItem {
  /** The u_key of the related custom field */
  readonly id: string;
  /** Read only name of the field */
  readonly name: string;
  readonly type: FieldDataType;
  helpText?: string;
  /** User configurable name of the field */
  label: string;
  placeholder?: string;
  required?: boolean;
}

export interface IListRowItem extends IRowItem {
  options: string[];
  type: FieldDataType.list;
  multi: boolean;
}

export interface IFormConfig {
  settings: {
    buttonShape: 'square' | 'rounded' | 'pill';
    submitLabel: string;
    submitButtonColor: string;
    submitButtonTextColor: string;
    typeface: string;
    fieldLabelSize: string;
    fieldLabelColor: string;
    helpTextSize: string;
    helpTextColor: string;
    buttonColor: string;
    buttonTextColor: string;
  };
  rows: Array<{ fields: Array<IRowItem | IListRowItem> }>;
}

export type JSONString<T> = string & { __type__: T };

interface IFormSettings {
  /** Thank you message JSON. The backend provides a default value. */
  post_submit_html: string;
  post_submit_redirect_url?: string;
  should_redirect_on_submit: boolean;
}

interface IFormNotificationSettings {
  should_email: boolean;
  email_addresses: string[];
}

export interface IForm extends IBaseAttributes {
  account: string;
  name: string;
  state: EFormState;
  /** Form JSON */
  content_json: JSONString<IFormConfig>;
  /** Project ID */
  event: string;
  height: number;
  /** Content serving domain */
  redirect_domain: IRedirectDomain;
  stats: IFormStats;
  /** Post submit HTML settings */
  settings: IFormSettings;
  notification_settings: IFormNotificationSettings;
  version: number;
  date_last_modified: string;
  date_last_published: string;
  last_published_version: number;
}

interface IPublishedForm extends IBaseAttributes {
  account: string;
  name: string;
  event: string;
  height: number;
  version: number;
  content_html: string;
  form: string;
  redirect: string;
  redirect_domain: string;
  short_code: string | undefined;
}

export class Form extends DisplayModel<IForm> {
  public readonly className = 'Form';
  public override collection: Forms<this> | null = null;

  public get constraints(): TConstraints<IForm> {
    return {
      name: {
        presence: {
          allowEmpty: false,
        },
      },
      'notification_settings.email_addresses': (...args: any[]): IConstraint | undefined => {
        const model = args[3].model;
        // If the form should send email notifications, at least one email address is required
        if (model.get('notification_settings')?.should_email) {
          return {
            list: {
              email: {
                message: '^Enter a valid email address',
              },
            },
            length: {
              minimum: 1,
              tooShort: '^Enter at least one email address',
              maximum: 5,
              tooLong: '^Enter up to five email addresses',
            },
          };
        }
        return undefined;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'settings.post_submit_html': (...args: any[]): IConstraint | undefined => {
        const model = args[3].model;
        // If the form should not redirect on submit, the thank you message is required
        if (!model.get('settings')?.should_redirect_on_submit) {
          return {
            // By default, a blank thank you message is '<p></p>'
            equality: {
              attribute: 'settings.post_submit_html',
              message: '^Enter a thank you message',
              comparator: (value: string) => value !== '<p></p>',
            },
          };
        }
        return undefined;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'settings.post_submit_redirect_url': (...args: any[]): IConstraint | undefined => {
        const model = args[3].model;
        // If the form should redirect on submit, the URL is required
        if (model.get('settings')?.should_redirect_on_submit) {
          return {
            presence: {
              allowEmpty: false,
              message: '^Enter a URL',
            },
            url: {
              message: '^Enter a valid URL',
            },
          };
        }
        return undefined;
      },
    };
  }

  constructor(attributes: Partial<IForm> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/projects/${this.get('event')}/content/forms/${this.id}`, pathSuffix);
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Form';
  }

  /**
   * Returns the content_json as an IFormConfig object for the Form Editor.
   */
  @computed
  public get formConfig(): IFormConfig {
    const content = this.get('content_json', '{}' as JSONString<IFormConfig>);
    return JSON.parse(content);
  }

  @computed
  public get published(): boolean {
    return this.get('state', EFormState.Draft) === EFormState.Published;
  }

  @computed
  public get usedFields(): IRowItem[] {
    return this.formConfig.rows?.flatMap((row) => row.fields) ?? [];
  }

  @computed
  public get previewUrl(): string {
    this.assertCollection(this.collection);
    return `${this.collection.url()}${this.id}/preview`;
  }

  @action
  public setConfig(config: IFormConfig): void {
    this.set({ content_json: JSON.stringify(config) as JSONString<IFormConfig> });
  }

  public async publish(): Promise<Form> {
    this.assertCollection(this.collection);

    return this.collection.publish(this);
  }

  /**
   * @throws {AggregateError} If there is a problem fetching the published form
   */
  public async getPublishedForm(
    redirectDomainId: IRedirectDomain['id'],
  ): Promise<IPublishedForm | undefined> {
    this.assertCollection(this.collection);

    return this.collection.getPublishedForm(this.id, redirectDomainId);
  }

  public async createPublishedForm(
    redirectDomainId: IRedirectDomain['id'],
  ): Promise<IPublishedForm> {
    this.assertCollection(this.collection);

    if (this.isDirty) {
      await this.patchDirty();
    }

    const url = `${this.collection.url()}${this.id}/published_form`;
    const response = await wretch<IPublishedForm>(url, {
      method: 'POST',
      headers: this.collection.getHeaders(),
      body: JSON.stringify({ redirect_domain_id: redirectDomainId }),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  /**
   * Ensure the form is published and available for the specified redirect domain
   *
   * @throws {AggregateError} when:
   *   - form is not found
   *   - publishing form fails
   *   - loading published form fails
   *   - creating published form fails
   */
  public async ensurePublishedForm(
    redirectDomainId: IRedirectDomain['id'],
  ): Promise<IPublishedForm> {
    this.assertCollection(this.collection);

    return this.collection.ensurePublishedForm(this.id, redirectDomainId);
  }

  @action
  public updateDesign(setting: keyof IFormConfig['settings'], value: string): void {
    this.setConfig({
      ...this.formConfig,
      settings: {
        ...this.formConfig.settings,
        [setting]: value,
      },
    });
  }
}

export class Forms<Model extends Form = Form> extends Collection<Model> {
  public getClassName(): string {
    return 'forms';
  }

  public getModel(attributes: Partial<Attributes<Model>>): Model {
    return new Form(attributes) as Model;
  }

  /**
   * @throws {AggregateError} If there is a problem fetching the form
   */
  public async getPublishedForm(
    id: string,
    redirectDomainId: IRedirectDomain['id'],
  ): Promise<IPublishedForm | undefined> {
    const query = toQueryString(
      this.parseJSONParams({ filters: { redirect_domain: redirectDomainId } }),
    );
    const url = `${this.url()}${id}/published_form?${query}`;
    const response = await wretch<IPublishedForm[]>(url, {
      method: 'GET',
      headers: this.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    if (response.data.length === 0) {
      return undefined;
    }

    return response.data[0];
  }

  @action
  public async publish(model: Model): Promise<Model> {
    if (model.isDirty) {
      await model.patchDirty();
    }

    model.isUpdating = true;
    const response = await wretch<IForm>(`${this.url()}${model.id}/publish`, {
      method: 'POST',
      headers: this.getHeaders(),
    });

    if (isWretchError(response)) {
      runInAction(() => {
        model.isUpdating = false;
        model.isErrored = true;
        model.error = response.error;
      });
      return model;
    }
    return this.processJSONResponse(response);
  }

  /**
   * Ensure a form is published and available for the specified redirect domain
   *
   * @throws {AggregateError} when:
   *   - form is not found
   *   - publishing form fails
   *   - loading published form fails
   *   - creating published form fails
   */
  @action
  public async ensurePublishedForm(
    id: string,
    redirectDomainId: IRedirectDomain['id'],
  ): Promise<IPublishedForm> {
    // Load form
    const form = this.get(id);
    await when(() => !form.isPending);
    if (form.isErrored) {
      throw form.error;
    }

    // Publish form if necessary
    if (form.get('state') !== EFormState.Published) {
      const updatedForm = await form.publish();
      if (updatedForm.isErrored) {
        throw updatedForm.error;
      }
    }

    // Load published form
    // getPublishedForm() will throw an error if there is a problem
    const publishedForm = await form.getPublishedForm(redirectDomainId);
    // getPublishedForm() returns empty if no published form exists
    if (!publishedForm) {
      // Create published form instance using form id and redirect domain id set
      const createdPublishedForm = await form.createPublishedForm(redirectDomainId);
      if (createdPublishedForm.isErrored) {
        throw createdPublishedForm.error;
      }
      return createdPublishedForm;
    }
    if (publishedForm.isErrored) {
      throw publishedForm.error;
    }
    return publishedForm;
  }
}
