import { Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { Campaign } from '@feathr/blackbox';
import { CampaignState, isPinpointCampaign } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { ConfirmModal, toast } from '@feathr/components';
import CampaignSummary from '@feathr/extender/App/EventsPage/CampaignSummary';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import EventSelect from '../EventSelect';

interface ICampaignDuplicateModalProps {
  campaign: Campaign;
  opened: boolean;
  close: () => void;
}

function CampaignDuplicateModal({
  campaign,
  opened,
  close,
}: Readonly<ICampaignDuplicateModalProps>): JSX.Element {
  const rootStore = useStore();
  const { Events } = rootStore;
  const history = useHistory();
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  const [selectedProject, setSelectedProject] = useState<ISelectOption>();
  const state = campaign.get('state');
  const isNotDraft = state !== CampaignState.Draft;
  const eventId = campaign.get('event');
  const eventName = eventId ? Events.get(eventId).name : '';

  const flightOrProject = campaign.get('flight') ? t('flight') : t('project');
  const flightOrProjectURL = campaign.get('flight')
    ? localUrl(campaign.getItemUrl())
    : localUrl(`/projects/${campaign.get('parent')}/report`);

  const alertPageLink = (
    <a href={flightOrProjectURL} target={'_blank'}>
      {t('View {{flightOrProject}} report', { flightOrProject })}
    </a>
  );

  // Select the current event anytime the duplicate modal is triggered
  useEffect(() => {
    if (!selectedProject) {
      setSelectedProject({ id: eventId, name: eventName });
    }
  }, [opened]);

  async function duplicate(): Promise<void> {
    try {
      const { Campaigns, Flights } = rootStore;
      const duplicatedCampaign: Campaign = await campaign.clone(
        selectedProject ? { event: selectedProject?.id } : {},
      );

      const flightId = campaign.get('flight');
      if (flightId) {
        try {
          await Flights.reload(flightId);
        } catch (error: any) {
          // If flight hasn't been loaded, just keep going, else throw
          if (error.message !== `Model by id ${flightId} does not exist in collection`) {
            throw error;
          }
        }
      }
      Campaigns.clearApiCache();
      history.push(localUrl(duplicatedCampaign.getItemUrl()));

      toast(
        t('Campaign "{{name}}" has been duplicated to {{- eventName}}', {
          name: campaign.name,
          eventName: selectedProject?.name ?? eventName,
        }),
      );
      close();
    } catch (error: any) {
      toast(
        t('Encountered an error while duplicating:\n{{- errorMessage}}', {
          errorMessage: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  function handleChangeEvent(event: ISelectOption): void {
    setSelectedProject(event);
  }

  function handleClearEvent(): void {
    setSelectedProject(undefined);
  }

  return (
    <ConfirmModal
      alertDescription={
        isNotDraft
          ? t(
              'To recalculate conversions to include the newly duplicated campaign, select "Recalculate conversions" from the conversions table on this flight\'s report.',
              { flightOrProject },
            )
          : undefined
      }
      alertLinks={isNotDraft && alertPageLink}
      alertTitle={isNotDraft && t('Are you sure you want to duplicate this campaign?')}
      cancelButtonText={t('Cancel')}
      confirmButtonText={t('Duplicate')}
      onClose={close}
      onConfirm={duplicate}
      opened={opened}
      t={t}
      title={t('Duplicate Campaign')}
    >
      <Stack>
        {isPinpointCampaign(campaign) && (
          <EventSelect
            helpText={t('This campaign will be duplicated to the selected project.')}
            id={eventId}
            label={t('Project')}
            onChange={handleChangeEvent}
            onClear={handleClearEvent}
            placeholder={t('Select a project...')}
            value={selectedProject}
          />
        )}
        <CampaignSummary campaign={campaign} layout={'vertical'} />
      </Stack>
    </ConfirmModal>
  );
}

export default observer(CampaignDuplicateModal);
