import { faBadgeCheck, faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { TFunction } from 'i18next';
import type { IObservableArray } from 'mobx';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { RowRenderProps } from 'react-table';

import type { CustomField, Person } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { AvatarV2 as Avatar, Chip, Spinner, TableColumnHeader, Time } from '@feathr/components';
import PersonName from '@feathr/extender/components/PersonName';
import { useStore } from '@feathr/extender/state';
import { cssVar, TimeFormat } from '@feathr/hooks';

import { DateCell, fieldTypeToCellMap, ListCell } from '../DataTableCells';
import PersonOptions from './PersonOptions';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './PersonColumns.css';

function ReachableCell({ original }: RowRenderProps): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { Persons } = useStore();

        const model = Persons.get(original.id);
        const icon = model.reachable ? faBadgeCheck : faMinusCircle;
        const color = model.reachable ? cssVar('--color-brand-green') : cssVar('--color-disabled');
        return <FontAwesomeIcon icon={icon} size={'2x'} style={{ color }} />;
      }}
    </Observer>
  );
}

function TagsCell({ original }: RowRenderProps): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { Tags } = useStore();
        const tagIds = original.get('tag_ids');

        return tagIds && tagIds.length > 0 ? (
          tagIds.map((tagId: string) => {
            const tag = Tags.get(tagId);
            return (
              <Chip key={tagId} theme={'tag'}>
                {!tag.isPending ? tag.name : <Spinner size={14} />}
              </Chip>
            );
          })
        ) : (
          <>-</>
        );
      }}
    </Observer>
  );
}

function defaultPersonColumns(t: TFunction): Array<IColumn<Person>> {
  return [
    {
      id: 'name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Name'),
      }),
      headerClassName: tableStyles.sort,
      width: 250,
      className: classNames(tableStyles.cell, styles.person),
      Cell({ original }): JSX.Element {
        const person = original;
        return (
          <>
            <Avatar name={person.get('name')} placeholder={person.get('placeholder')} />
            <div>
              <PersonName hasLink={true} person={person.toJS()} />
              {original.get('external_id') && <span>{original.get('external_id')}</span>}
            </div>
          </>
        );
      },
    },
    {
      id: 'email',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Primary email'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return <>{original.get('email') || '-'}</>;
      },
    },
    {
      id: 'phone',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Phone'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return <>{original.get('phone') || '-'}</>;
      },
    },
    {
      id: 'occupation',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Occupation'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return <>{original.get('occupation') || '-'}</>;
      },
    },
    {
      id: 'companies',
      Header: TableColumnHeader({
        title: t('Companies'),
      }),
      sortable: false,
      className: tableStyles.cell,
      Cell: ListCell('companies'),
    },
    {
      id: 'tag_ids',
      Header: TableColumnHeader({
        title: t('Tags'),
      }),
      sortable: false,
      Cell(row): JSX.Element {
        return <TagsCell {...row} />;
      },
    },
    {
      id: 'reachable',
      Header: TableColumnHeader({
        title: t('Reachable'),
      }),
      sortable: false,
      className: tableStyles.cellCenter,
      Cell(row): JSX.Element {
        return <ReachableCell {...row} />;
      },
    },
    {
      id: 'date_last_seen',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Last Seen'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellCenter,
      Cell: DateCell('date_last_seen', undefined, TimeFormat.timeFromNow),
    },
    {
      id: 'date_created',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('First Seen'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return original.get('date_created') ? (
          <Time format={TimeFormat.timeFromNow} timestamp={original.get('date_created')} />
        ) : (
          <>-</>
        );
      },
    },
    {
      id: 'seen_count',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Times Seen'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      Cell({ original }): JSX.Element {
        return <>{original.get('seen_count') || '-'}</>;
      },
    },
    {
      id: 'session_count',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Sessions'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      Cell({ original }): JSX.Element {
        return <>{original.get('session_count') || '-'}</>;
      },
    },
    {
      id: 'date_last_matched_activity',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Latest Activity (filtered)'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellCenter,
      Cell: DateCell('date_last_matched_activity', undefined, TimeFormat.timeFromNow, '-'),
    },
    {
      id: 'conv_count',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Times Converted'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      Cell({ original }): JSX.Element {
        return <>{original.get('conv_count') || '-'}</>;
      },
    },
    {
      id: 'address.premise1',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Address: Line 1'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        const address = original.get('address');
        return <>{address.premise1 || '-'}</>;
      },
    },
    {
      id: 'address.premise2',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Address: Line 2'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        const address = original.get('address');
        return <>{address.premise2 || '-'}</>;
      },
    },
    {
      id: 'address.locality',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Address: City'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        const address = original.get('address');
        return <>{address.locality || '-'}</>;
      },
    },
    {
      id: 'address.administrative_area_name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Address: State'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        const address = original.get('address');
        return <>{address.administrative_area_name || '-'}</>;
      },
    },
    {
      id: 'address.postal_code',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Address: Zip/Postal Code'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        const address = original.get('address');
        return <>{address.postal_code || '-'}</>;
      },
    },
    {
      id: 'geoip.lat',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('IP: Latitude'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        const geoip = original.get('geoip');
        return <>{geoip.lat || '-'}</>;
      },
    },
    {
      id: 'geoip.lng',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('IP: Longitude'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        const geoip = original.get('geoip');
        return <>{geoip.lng || '-'}</>;
      },
    },
    {
      id: 'geoip.country_name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('IP: Country Name'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        const geoip = original.get('geoip');
        return <>{geoip.country_name || '-'}</>;
      },
    },
    {
      id: 'geoip.country_code',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('IP: Country'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        const geoip = original.get('geoip');
        return <>{geoip.country_code || '-'}</>;
      },
    },
    {
      id: 'geoip.locality',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('IP: City'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        const geoip = original.get('geoip');
        return <>{geoip.locality || '-'}</>;
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: t('Options'),
      }),
      className: tableStyles.cellCenter,
      width: 80,
      sortable: false,
      Cell(row): JSX.Element {
        return <PersonOptions {...row} />;
      },
    },
  ];
}

function PersonColumns(
  customFields: IObservableArray<CustomField>,
  t: TFunction,
): Array<IColumn<Person>> {
  return [
    ...defaultPersonColumns(t),
    ...customFields.map((field) => ({
      id: `custom.${field.get('f_key')}`,
      Header: TableColumnHeader({
        title: field.get('u_key'),
      }),
      sortable: true,
      Cell: fieldTypeToCellMap.get(field.get('data_type'))!(field.get('u_key'), 'custom_data'),
    })),
  ];
}

export function filterPersonColumns(
  columnIds: string[],
  customFields: IObservableArray<CustomField>,
  t: TFunction,
): Array<IColumn<Person>> {
  const columns = PersonColumns(customFields, t);
  return columns.filter((column) => columnIds.includes(column.id!));
}

export default PersonColumns;
